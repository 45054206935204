// Variable overrides
$blue:     #007bff !default;
$indigo:   #6610f2 !default;
$purple:   #6f42c1 !default;
$pink:     #e83e8c !default;
$red:      #dc3545 !default;
$orange:   #fd7e14 !default;
$yellow:   #ffc107 !default;
$green:    #28a745 !default;
$teal:     #20c997 !default;
$cyan:     #17a2b8 !default;
$white:       #fff !default;
$gray:     #6c757d !default;
$gray-dark:#343a40 !default;
$primary:  #009FE3 !default;
$secondary:#F2F2F2 !default;
$success:  #28a745 !default;
$info:     #17a2b8 !default;
$warning:  #ffc107 !default;
$danger:   #dc3545 !default;
$light:    #f8f9fa !default;
$dark:     #343a40 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray,
  gray-dark: $gray-dark,
) !default;

$theme-colors: (
  primary: $primary,
  success: $success,
  info: $primary,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
) !default;
