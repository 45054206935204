// Here you can add other styles
@font-face {
    font-family: 'Averta';
    src: local('Averta'), url(../assets/fonts/Averta-Regular.woff) format('woff');
}

body {
    font-family: 'Averta' !important;
}

.data-table-bje {
    background-color: white;
    padding: 2px; 
}


.ratings-star-wrapper {
    vertical-align: top;
    display: inline-block;
    text-align: center;
}

.ratings-text {
    font-size: xx-large;
}

.dataTables_wrapper .dataTables_filter {
    text-align: left !important;
}

.react-rte .public-DraftEditor-content {
    min-height: 200px;
}

.cc-card-header {
    background-color: #f0f3f5;
}
/*
.clickable-tag-cloud .tag-cloud-tag:hover{
    cursor: pointer;
    text-decoration: underline;
}
*/
